const UserPermissionsArr = {
  companies: {
    create: false,
    update: false,
    read: false,
    delete: false,
  },
  // firmware: {
  //   create: false,
  //   update: false,
  //   read: false,
  //   delete: false,
  // },
  // config: {
  //   create: false,
  //   update: false,
  //   read: false,
  //   delete: false,
  // },
  // debug: {
  //   create: false,
  //   update: false,
  //   read: false,
  //   delete: false,
  // },
  devices: {
    create: false,
    update: false,
    read: false,
    delete: false,
  },
  // groups: {
  //   create: false,
  //   update: false,
  //   read: false,
  //   delete: false,
  // },
  // integrations: {
  //   create: false,
  //   update: false,
  //   read: false,
  //   delete: false,
  // },
  // logs: {
  //   create: false,
  //   update: false,
  //   read: false,
  //   delete: false,
  // },
  // roles: {
  //   create: false,
  //   update: false,
  //   read: false,
  //   delete: false,
  // },
  // settings: {
  //   create: false,
  //   update: false,
  //   read: false,
  //   delete: false,
  // },
  users: {
    create: false,
    update: false,
    read: false,
    delete: false,
  },
  // configLogs: {
  //   create: false,
  //   update: false,
  //   read: false,
  //   delete: false,
  // },
  // assembly: {
  //   create: false,
  //   update: false,
  //   read: false,
  //   delete: false,
  // },
  // myGroups: {
  //   create: false,
  //   update: false,
  //   read: false,
  //   delete: false,
  // },
  // crm: {
  //   create: false,
  //   update: false,
  //   read: false,
  //   delete: false,
  // },
  devicesManagement: {
    create: false,
    update: false,
    read: false,
    delete: false,
  },
  operations: {
    create: false,
    update: false,
    read: false,
    delete: false,
  },
  platform: {
    create: false,
    update: false,
    read: false,
    delete: false,
  },
};

export { UserPermissionsArr };
