/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, {
  Suspense, lazy, useEffect, useState,
} from 'react';
import {
  Route, Redirect, NavLink,
} from 'react-router-dom';
import { Switch, useHistory } from 'react-router';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {
  Box,
  List,
  CssBaseline,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  Menu, ExpandLess, ExpandMore, AccountCircle, Groups, ExitToApp,
} from '@mui/icons-material';
import KSC from './Components/KSC';
import { useUserContext } from './context/user-context';

const MyGroups = lazy(() => import('./routes/MyGroups/MyGroups'));
const NotFound = lazy(() => import('./NotFound'));

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  background: '#eee',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  background: '#eee',
  width: `calc(${theme.spacing(window.top === window.self ? 7.5 : 0)})`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(window.top === window.self ? 7.5 : 0)})`,
  },
});

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'flex-end',
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   // ...theme.mixins.toolbar,
// }));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const NavigationDrawer = ({ router, keycloak }) => {
  const { accountUser, permissions } = useUserContext();
  const [open, setOpen] = useState(false);
  const [logoChange, setLogoChange] = useState(false);
  const [openDev, setOpenDev] = useState(false);
  const [openPanels, setOpenPanels] = useState({});
  const [openAccount, setOpenAccount] = useState(false);

  const handleLogOut = () => {
    if (keycloak) {
      keycloak.logout();
    }
  };

  function handlePanel(panelName) {
    setOpenPanels((prevOpenPanels) => ({
      // ...prevOpenPanels,
      [panelName]: !prevOpenPanels[panelName],
    }));
    setOpen(true);
    setOpenAccount(false);
  }

  return (
    <Drawer
      sx={{ display: window.top === window.self ? 'block' : 'none' }}
      variant="permanent"
      open={open}
      onMouseLeave={() => {
        setOpenDev(false);
        setOpenAccount(false);
        setOpen(false);
      }}
    >
      <List
        component="nav"
        sx={{ padding: '0px' }}
        onMouseEnter={() => { setLogoChange(true); }}
        onMouseLeave={() => { setLogoChange(false); }}
      >
        <ListItemButton disableRipple onClick={() => setOpen(!open)}>
          <ListItemIcon style={{ color: '#008081' }}>
            {open || logoChange ? (
              <Menu />
            ) : (
              <img
                src="/img/logo.svg"
                alt="company logo"
                style={{ maxHeight: '20px' }}
              />
            )}
          </ListItemIcon>
          <img
            src="/img/logo1.png"
            alt="company logo"
            style={{ maxHeight: '40px', marginTop: '10px' }}
          />
        </ListItemButton>
        <Divider />
      </List>
      <List component="nav" style={{ padding: '0px' }}>
        {router.map((navItem) => (
          <span key={navItem.path || navItem.name}>
            <ListItemButton
              disableRipple
              key={navItem.path || navItem.name}
              component={navItem.more ? 'div' : NavLink}
              to={navItem.more ? '' : `/${navItem.path}`}
              // onClick={navItem.more ? () => { setOpen(true); setOpenAccount(false); setOpenDev(!openDev); } : () => {}}
              onClick={() => handlePanel(navItem.name)}
            >
              <ListItemIcon style={{ color: '#008081' }}>
                {navItem.icon}
              </ListItemIcon>
              <ListItemText primary={navItem.name} />
              {navItem.more && (openPanels[navItem.name] ? <ExpandLess sx={{ color: '#008081' }} /> : <ExpandMore sx={{ color: '#008081' }} />)}
            </ListItemButton>
            {navItem.more && (
            <Collapse in={openPanels[navItem.name]} timeout="auto" unmountOnExit>
              <List component="nav" disablePadding>
                {navItem.more.map((navSubItem) => (
                  <ListItemButton
                    disableRipple
                    key={navSubItem.path || navSubItem.name}
                    component={NavLink}
                    to={`/${navSubItem.path}`}
                    style={{ paddingLeft: '30px' }}
                  >
                    <ListItemIcon style={{ color: '#008081' }}>
                      {navSubItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={navSubItem.name} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
            )}
          </span>
        ))}

        {/* for my acc */}
        <ListItemButton
          disableRipple
        // onClick={() => handleAccountPanel()}
          onClick={() => { setOpen(true); setOpenPanels({}); setOpenAccount(!openAccount); }}
        >
          <ListItemIcon style={{ color: '#008081' }}>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText
            primary={accountUser.given_name || accountUser.family_name || accountUser.email}
            primaryTypographyProps={{ style: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' } }}
          />
          {openAccount ? <ExpandLess sx={{ color: '#008081' }} /> : <ExpandMore sx={{ color: '#008081' }} />}
        </ListItemButton>
        <Collapse in={openAccount} timeout="auto" unmountOnExit>
          <List component="nav" disablePadding>
            {permissions.users?.read && (
            <ListItemButton
              disableRipple
              key="myGroups"
              component={NavLink}
              to="/myGroups"
              style={{ paddingLeft: '30px' }}
            >
              <ListItemIcon style={{ color: '#008081' }}>
                <Groups fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' } }}
                primary="My Groups"
              />
            </ListItemButton>
            )}
            <ListItemButton
              disableRipple
              key="logout"
              onClick={() => { handleLogOut(); }}
              style={{ paddingLeft: '30px' }}
            >
              <ListItemIcon style={{ color: '#008081' }}>
                <ExitToApp fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
};

export const Navigation = ({ routes, keycloak }) => {
  const theme = useTheme();
  const { permissions } = useUserContext();
  const [router, setRouter] = useState([]);

  const history = useHistory();
  const currentPath = history.location.pathname;

  const filteredRoutes = () => {
    const ansRoutes = [];
    routes.map((route) => {
      if (route && route.path === 'devices' && (permissions?.devices?.read || permissions?.devicesManagement?.read || permissions?.operations?.create)) {
        const filteredSubRoutes = [];
        route.more.forEach((subRoute) => {
          if (subRoute && subRoute.path === 'devices' && permissions?.devices?.read) {
            filteredSubRoutes.push(subRoute);
          } else if (subRoute && subRoute.path === 'devices/bulkConfig') {
            if (permissions?.devicesManagement?.update) {
              filteredSubRoutes.push(subRoute);
            }
          } else if (subRoute && subRoute.path === 'devices/recurringFOTA') {
            if (permissions?.devicesManagement?.read) {
              filteredSubRoutes.push(subRoute);
            }
          } else if (subRoute && subRoute.path === 'devices/simconfig') {
            if (permissions?.devices?.update) {
              filteredSubRoutes.push(subRoute);
            }
          } else if (subRoute && subRoute.path === 'devices/bulkTest' && permissions?.operations?.create) {
            filteredSubRoutes.push(subRoute);
          } else if (subRoute && subRoute.path === 'devices/batterylife' && permissions?.devicesManagement?.read) {
            filteredSubRoutes.push(subRoute);
          }
        });
        ansRoutes.push({ ...route, more: filteredSubRoutes });
      }
      if (route && route.path === 'usermanagement') {
        if (permissions?.users?.read) {
          ansRoutes.push(route);
        }
      }
      if (route && route.path === 'firmware') {
        if (permissions?.devicesManagement?.read) {
          ansRoutes.push(route);
        }
      }
      if (route && route.path === 'apikeys') {
        if (permissions?.integrations?.read) {
          ansRoutes.push(route);
        }
      }
      if (route && route.path === 'applications') {
        if (permissions?.companies?.read) {
          ansRoutes.push(route);
        }
      }
      // if (route && route.path === "configureDevice") {
      //   if (permissions?.config?.read) {
      //     ansRoutes.push(route);
      //   }
      // }
      if (route && route.path === 'manufacturing') {
        if (permissions?.operations?.create) {
          ansRoutes.push(route);
        }
      }

      if (route && route.path === 'platformManagement') {
        if (permissions?.platform?.read) {
          const filteredSubRoutes = [];
          route.more.forEach((subRoute) => {
            if (subRoute && subRoute.path === 'platformManagement/newDeviceOnboarding') {
              filteredSubRoutes.push(subRoute);
            }
            ansRoutes.push({ ...route, more: filteredSubRoutes });
          });
        }
      }

      if (route && route.path === 'operationalCheck' && permissions?.operations?.read) {
        const filteredSubRoutes = [];
        route.more.forEach((subRoute) => {
          if (subRoute && subRoute.path === 'operationalCheck/postProductionInspection') {
            // if (permissions?.devices?.update) {
            filteredSubRoutes.push(subRoute);
            // }
          }
        });
        ansRoutes.push({ ...route, more: filteredSubRoutes });
      }
      if (route && route.path === 'crm' && permissions?.crm?.read) {
        ansRoutes.push(route);
      }
      if (route && route.path === 'bulk-testing' && permissions?.operations?.create) {
        ansRoutes.push(route);
      }
      if (route && route.path === 'myGroups') {
        ansRoutes.push(route);
      }
    });
    return ansRoutes;
  };

  useEffect(() => {
    const filteredRoute = filteredRoutes();
    setRouter(filteredRoute);
  }, [permissions]);

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      <NavigationDrawer keycloak={keycloak} router={router} />
      <Suspense fallback={<div />}>
        <Box
          sx={{
            flexGrow: 1,
            left: `calc(${theme.spacing(window.top === window.self ? 7.5 : 0)})`,
            width: `calc(100% - calc(${theme.spacing(window.top === window.self ? 7.5 : 0)}))`,
            height: '100%',
            position: 'absolute',
          }}
        >
          <Switch>
            {routes.flatMap((route) => {
              if (route.path === 'devices') {
                return route.more.map((subRoute) => {
                  if (subRoute.path === 'devices' && permissions?.devices?.read) {
                    return (
                      <Route
                        key={subRoute.path}
                        path={`/${subRoute.path}`}
                        render={(routeProps) => subRoute.component({ ...routeProps })}
                        exact={subRoute.path === route.path}
                      />
                    );
                  }
                  if (subRoute.path === 'devices/bulkConfig' && permissions?.devicesManagement?.update) {
                    return (
                      <Route
                        key={subRoute.path}
                        path={`/${subRoute.path}`}
                        render={(routeProps) => subRoute.component({ ...routeProps })}
                        exact={subRoute.path === route.path}
                      />
                    );
                  }
                  if (subRoute.path === 'devices/recurringFOTA' && permissions?.devicesManagement?.read) {
                    return (
                      <Route
                        key={subRoute.path}
                        path={`/${subRoute.path}`}
                        render={(routeProps) => subRoute.component({ ...routeProps })}
                        exact={subRoute.path === route.path}
                      />
                    );
                  }
                  if (subRoute.path === 'devices/bulkTest' && permissions?.operations?.create) {
                    return (
                      <Route
                        key={subRoute.path}
                        path={`/${subRoute.path}`}
                        render={(routeProps) => subRoute.component({ ...routeProps })}
                        exact={subRoute.path === route.path}
                      />
                    );
                  }
                  if (subRoute.path === 'devices/batterylife' && permissions?.devicesManagement?.read) {
                    return (
                      <Route
                        key={subRoute.path}
                        path={`/${subRoute.path}`}
                        render={(routeProps) => subRoute.component({ ...routeProps })}
                        exact={subRoute.path === route.path}
                      />
                    );
                  }
                });
              }
              if (route.path === 'firmware') {
                return (
                  permissions?.devicesManagement?.read && [
                    <Route
                      key={route.path}
                      path={`/${route.path}`}
                      render={(routeProps) => route.component({ ...routeProps })}
                    />,
                  ]
                );
              }
              if (route.path === 'usermanagement') {
                if (permissions?.users?.read) {
                  return [
                    <Route
                      key={route.path}
                      path={`/${route.path}`}
                      render={(routeProps) => route.component({ ...routeProps })}
                    />,
                  ];
                }
              }
              if (route.path === 'apikeys') {
                if (permissions?.integrations?.read) {
                  return [
                    <Route
                      key={route.path}
                      path={`/${route.path}`}
                      render={(routeProps) => route.component({ ...routeProps })}
                    />,
                  ];
                }
              }
              if (route.path === 'applications') {
                if (permissions?.companies?.read) {
                  return [
                    <Route
                      key={route.path}
                      path={`/${route.path}`}
                      render={(routeProps) => route.component({ ...routeProps })}
                    />,
                  ];
                }
              }
              if (route.path === 'configureDevice') {
                if (permissions?.config?.read) {
                  return [
                    <Route
                      key={route.path}
                      path={`/${route.path}`}
                      render={(routeProps) => route.component({ ...routeProps })}
                    />,
                  ];
                }
              }
              if (route.path === 'manufacturing') {
                return [
                  <Route
                    key={route.path}
                    path={`/${route.path}`}
                    render={(routeProps) => route.component({ ...routeProps })}
                  />,
                ];
              }
              if (route.path === 'platformManagement' && permissions?.platform?.read) {
                return (
                  route.more.map((subRoute) => (
                    <Route
                      key={subRoute.path}
                      path={`/${subRoute.path}`}
                      render={(routeProps) => subRoute.component({ ...routeProps })}
                      exact={subRoute.path === route.path}
                    />
                  ))
                );
              }
              if (route.path === 'operationalCheck') {
                return (
                  route.more.map((subRoute) => (
                    <Route
                      key={subRoute.path}
                      path={`/${subRoute.path}`}
                      render={(routeProps) => subRoute.component({ ...routeProps })}
                      exact={subRoute.path === route.path}
                    />
                  ))
                );
              }
              if (route.path === 'crm') {
                return [
                  <Route
                    key={route.path}
                    path={`/${route.path}`}
                    render={(routeProps) => route.component({ ...routeProps })}
                  />,
                ];
              }
              if (route.path === 'bulk-testing' && permissions?.operations?.create) {
                return [
                  <Route
                    key={route.path}
                    path={`/${route.path}`}
                    render={(routeProps) => route.component({ ...routeProps })}
                  />,
                ];
              }
              if (route.path === 'myGroups') {
                return [
                  <Route
                    key={route.path}
                    path={`/${route.path}`}
                    render={(routeProps) => route.component({ ...routeProps })}
                  />,
                ];
              }
            })}
            {permissions.users?.read && (
              <Route
                key="myGroups"
                path="/myGroups"
                render={() => <MyGroups />}
              />
            )}
            <Route key="ksc" path="/ksc" component={KSC} />
            <Route key="home" exact path="/">
              <Redirect to={`/${router[0]?.path || 'devices'}`} />
            </Route>
            <Route key="404">
              <NotFound />
            </Route>
          </Switch>
        </Box>
      </Suspense>
    </Box>
  );
};
